<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent >
    <v-form v-model="valid" ref="formAdicionarCondutor" lazy-validation>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title class="white--text">Adicionar Condutor</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field label="Chave do Manifesto" v-model="manifesto.chave" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="CPF" v-model="condutor.cpf" v-mask="'###.###.###-##'"></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field label="Nome do Condutor" v-model="condutor.nome"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn text @click="fechar()">Cancelar</v-btn>
        <v-btn color="primary" @click="enviar()">Enviar</v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  
  import formatador from '@/util/formatador';

  export default {
    
    props: ['dialog', 'manifesto'],

    data: () => ({
      valid: false,
      condutor: {
        idManifesto: '',
        seqEvento: '',
        chave: '',
        cpf: '',
        nome: ''
      }
    }),

    methods: {
      
      fechar(){
          this.$emit('fechar');
          this.limpar();
      },

      enviar(){
        if(this.$refs.formAdicionarCondutor.validate()){
          if(this.manifesto.eventos){
            let quantidade = this.manifesto.eventos.length + 1
            this.condutor.seqEvento = formatador.formatarPadZero(quantidade, 2);
          }
          this.condutor.idManifesto = this.manifesto._id;
          this.condutor.chave = this.manifesto.chave;
          this.condutor.cpf = this.condutor.cpf.replace('.', '').replace('.', '').replace('-', '');

          this.$emit('adicionarCondutor', this.condutor);
          this.limpar();
        }
      },

      limpar(){
        this.adicao = {
            idManifesto: '',
            seqEvento: '',
            chave: '',
            cpf: '',
            nome: ''
        }
      }
    }
  }
</script>